export const UNVERIFIED = 'unverified'
export const VERIFIED = 'verified'

export const IdVerificationLabels = {
  [UNVERIFIED]: 'Unverified',
  [VERIFIED]: 'Verified',
}

const IdVerificationStatus = {
  UNVERIFIED,
  VERIFIED,
}

export default IdVerificationStatus
