<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Label</th>
            <th class="text-left">Published</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!form.subcategories.length">
            <td colspan="3" class="text-center">No data.</td>
          </tr>
          <tr v-for="(item, i) in form.subcategories" :key="i">
            <td>
              <v-text-field
                hide-details="auto"
                flat
                solo
                required
                class="mt-2"
                v-model="item.label"
                :error-messages="form.$getError(`subcategories.${i}.label`)"
              ></v-text-field>
            </td>
            <td>
              <v-switch
                class="mt-0"
                hide-details="auto"
                v-model="item.is_published"
              ></v-switch>
            </td>
            <td>
              <v-btn outlined color="error" @click="deleteSubcategory(item)"
                >Delete</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn @click="add()" class="mt-5">Add Sub-Category</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteSubcategory(subcategory) {
      this.form.subcategories.splice(
        this.form.subcategories.indexOf(subcategory),
        1
      )
    },
    add() {
      this.form.subcategories.push({
        label: null,
        is_published: false,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-text-field {
  min-width: 14rem;
}
</style>
