<template>
  <div class="d-flex flex-row align-stretch mb-8 avatar-wrapper">
    <div class="category-image-container">
      <img v-if="src" :src="src" alt="Category Image" />
      <img v-else src="@/assets/icons/icon-image.svg" alt="Category Image" />
    </div>
    <div class="d-flex flex-column justify-space-around ml-6">
      <input
        type="file"
        ref="imageFile"
        @change="onImageChange"
        style="display: none"
        accept="image/*"
      />
      <v-btn
        text
        class="text-capitalize font-weight-bold text-light-blue"
        @click="$refs.imageFile.click()"
      >
        <img src="@/assets/icons/icon-image.svg" class="mr-2" alt />Upload Image
      </v-btn>
      <v-btn
        v-if="src"
        text
        class="text-capitalize font-weight-bold text-light-grey"
        @click="$emit('remove')"
      >
        <img src="@/assets/icons/icon-trash.svg" class="mr-2" alt /> Delete
        Image
      </v-btn>
    </div>
  </div>
</template>

<script>
import { isImage } from '@/utils/file'
import Api from '@/services/api'

export default {
  props: {
    src: {
      type: String,
      default: null,
    },
  },
  methods: {
    async onImageChange({ target }) {
      const file = target.files[0]
      if (file && isImage(file)) {
        const form = new FormData()
        form.append('file', file)

        const response = await Api.post('media', form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.$emit('change', response.data.data)
      } else {
        this.$emit('remove')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.category-image-container {
  height: 160px;
  min-width: 160px;
  width: 160px;
  img {
    width: 100%;
  }
}
</style>
