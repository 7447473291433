<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'users' }"
          >Users</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4" v-if="form">
      <div class="display-2 font-weight-bold my-6 text-capitalize">
        {{ displayName }}
        <v-icon
          color="success"
          title="ID Verified"
          v-if="user.id_verification_status === IdVerificationStatus.VERIFIED"
          >{{ icons.checkOutline }}</v-icon
        >
      </div>
      <small v-if="user.deleted_at" class="red--text">Deleted Account</small>
      <div class="d-flex my-6">
        <v-tabs hide-slider class="user-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-md-12 col-sm-12 col-xs-12 mt-6 pa-0">
            <v-alert
              dense
              outlined
              type="warning"
              color="orange darken-3"
              max-width="400px"
              v-if="!!user.blocked_at"
            >
              This account is <strong>disabled</strong>.
            </v-alert>

            <avatar-form
              :user="user"
              @change="avatarChange"
              @avatarRemove="userAvatarRemoved"
              :avatarLoading="avatarLoading"
            />
            <div class="d-sm-block d-md-flex">
              <v-row>
                <v-col md="12">
                  <span>Rating: </span>
                  <v-rating
                    half-increments
                    v-model="user.rating"
                    background-color="orange lighten-3"
                    color="orange"
                    readonly
                    size="40"
                  />
                </v-col>
              </v-row>
            </div>

            <v-form ref="form" v-if="form" class="user-form">
              <v-divider></v-divider>

              <div class="d-sm-block d-md-flex">
                <div class="w-full">
                  <h3 class="py-5 primary--text">User Information</h3>

                  <label class="text-field-label">First Name</label>
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    v-model="form.first_name"
                    :error-messages="form.$getError('first_name')"
                    :loading="loading"
                  ></v-text-field>

                  <label class="text-field-label">Last Name</label>
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    v-model="form.last_name"
                    :error-messages="form.$getError('last_name')"
                    :loading="loading"
                  ></v-text-field>

                  <label class="text-field-label">Birth Date</label>

                  <v-menu
                    ref="menu"
                    v-model="datePickermenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.birthdate"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        flat
                        solo
                        v-model="form.birthdate"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="form.$getError('birthdate')"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.birthdate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="datePickermenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(form.birthdate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>

                  <label class="text-field-label">Business Name</label>
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    v-model="form.business_name"
                    :error-messages="form.$getError('business_name')"
                    :loading="loading"
                  ></v-text-field>

                  <label class="text-field-label">Email</label>
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    v-model="form.email"
                    :error-messages="form.$getError('email')"
                    :loading="loading"
                  >
                    <v-tooltip slot="append" top v-if="user.email">
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="success"
                          v-on="on"
                          v-if="user.email_verified"
                          >{{ icons.check }}</v-icon
                        >
                        <v-icon color="warning" v-on="on" v-else>{{
                          icons.alert
                        }}</v-icon>
                      </template>
                      <span v-if="user.email_verified">Email Verified</span>
                      <span v-else>Unverified email</span>
                    </v-tooltip>
                  </v-text-field>

                  <label class="text-field-label"
                    >Email Verification Code</label
                  >
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    v-model="form.email_verification_code"
                    :error-messages="form.$getError('email_verification_code')"
                    :loading="loading"
                  >
                  </v-text-field>

                  <label class="text-field-label">Phone number</label>
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    v-model="form.phone_number"
                    :error-messages="form.$getError('phone_number')"
                    :loading="loading"
                  >
                    <v-tooltip slot="append" top v-if="user.phone_number">
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="success"
                          v-on="on"
                          v-if="user.phone_number_verified"
                          >{{ icons.check }}</v-icon
                        >
                        <v-icon color="warning" v-on="on" v-else>{{
                          icons.alert
                        }}</v-icon>
                      </template>
                      <span v-if="user.phone_verified"
                        >Phone Number Verified</span
                      >
                      <span v-else>Unverified Phone Number</span>
                    </v-tooltip>
                  </v-text-field>

                  <label class="text-field-label">Postcode</label>
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    v-model="form.postcode"
                    :error-messages="form.$getError('postcode')"
                    :loading="loading"
                  ></v-text-field>

                  <label class="text-field-label">Address</label>

                  <location-field
                    name="home_address_place_id"
                    :id="home_address_place_id"
                    :text="addressText"
                    :error-message="form.$getError('home_address_place_id')"
                    @change="setLocation"
                  />
                </div>
              </div>

              <div class="user-form__actions" v-if="!user.deleted_at">
                <v-checkbox
                  :input-value="user && user.blocked_at"
                  label="Disable user account"
                  color="orange darken-3"
                  class="mt-0"
                  @mousedown="showModal = true"
                ></v-checkbox>

                <div class="mt-2">
                  <v-btn
                    color="error"
                    outlined
                    class="mr-4 px-6"
                    height="40px"
                    width="100%"
                    @click="deleteDialog = true"
                    >Delete</v-btn
                  >
                </div>
                <div class="mt-4">
                  <v-btn
                    color="primary"
                    class="mr-4 px-6"
                    height="40px"
                    width="100%"
                    @click="updateUserDetails"
                    :loading="form.$busy"
                    >Update</v-btn
                  >
                </div>
              </div>
            </v-form>

            <confirm-modal
              v-model="deleteDialog"
              title="Delete User"
              message="Are you sure you want to delete this user?"
              @cancel="deleteDialog = false"
              @confirm="deleteUser"
              confirm-color="error"
            />
          </v-tab-item>
          <v-tab-item class="col-md-12 col-sm-12 col-xs-12 mt-6 pa-0">
            <PasswordUpdateForm style="max-width: 400px" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>

    <ConfirmModal
      v-model="showModal"
      title="Disable Account"
      :message="disableMessage"
      @cancel="showModal = false"
      @confirm="blockAccount"
    ></ConfirmModal>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiCheck,
  mdiAlertCircleOutline,
  mdiCheckCircleOutline,
} from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import AvatarForm from './components/AvatarForm'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { pick, get } from 'lodash'

import Form from '@/utils/form'
import IdVerificationStatus from '@/enums/id-verification-status'
import LocationField from '../Listings/components/LocationField'
import Api from '@/services/api'
import PasswordUpdateForm from '@/views/Home/Users/components/PasswordUpdateForm'
import Snackbar from '@/utils/mixins/Snackbar'

export default {
  name: 'UserDetails',
  mixins: [Snackbar],

  components: {
    PasswordUpdateForm,
    AvatarForm,
    AppBarNavIcon,
    ConfirmModal,
    LocationField,
  },

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
        checkOutline: mdiCheckCircleOutline,
      },
      loading: false,
      avatarLoading: false,
      deleteDialog: false,
      form: null,
      showCropperModal: false,
      avatarTimestamp: new Date().getTime(),
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
      showModal: false,
      isLoadingDelete: false,
      IdVerificationStatus,
      datePickermenu: false,
      home_address_place_id: null,
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.user.userDetails,
      authUser: (state) => state.auth.user,
    }),
    displayName() {
      let firstName = this.form.first_name || ''
      let lastName = this.form.last_name || ''

      let displayName = firstName + ' ' + lastName

      return firstName || lastName ? displayName : this.form.email
    },
    disableMessage() {
      const action = this.user && this.user.blocked_at ? 'unblock' : 'block'
      return `Are you sure you want ${action} this account?`
    },

    addressText() {
      return this.user.home_address ? this.user.home_address.full_name : ''
    },

    tabItems() {
      return [
        { tab: 'Info' },
        ...(this.shouldShowPasswordUpdate ? [{ tab: 'Password Update' }] : []),
      ]
    },

    shouldShowPasswordUpdate() {
      return this.authUser.id === this.user.id
    },
  },

  methods: {
    ...mapActions({
      getUserDetails: 'user/getUserDetails',
      changeAvatar: 'user/changeAvatar',
      updateUser: 'user/updateUser',
      blockUser: 'user/blockUser',
    }),

    ...mapMutations({
      clearUserDetails: 'user/clearUserDetails',
      setSelectedUserAvatar: 'user/setSelectedUserAvatar',
      userAvatarRemoved: 'user/userAvatarRemoved',
    }),

    async getUser() {
      this.loading = true
      await this.getUserDetails(this.$route.params.id)
      this.resetForm()
      this.loading = false
    },

    resetForm() {
      this.form = new Form(
        pick(this.user, [
          'id',
          'first_name',
          'last_name',
          'email',
          'email_verification_code',
          'phone_number',
          'postcode',
          'birthdate',
          'business_name',
        ])
      )

      if (this.user.home_address) {
        this.home_address_place_id = this.user.home_address.place_id
      }
    },

    async avatarChange(image) {
      this.avatarLoading = true
      let data = new FormData()
      data.append('user_id', this.user.id)
      data.append('avatar', image)
      await this.changeAvatar(data)
      this.avatarLoading = false
      this.showSnackbar('Avatar successfully updated!', 'success')
    },

    async updateUserDetails() {
      this.form.$busy = true
      this.form.$clearErrors()
      const data = this.form.$data()

      data.home_address_place_id = this.home_address_place_id

      this.updateUser(data)
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('User details successfully updated!', 'success')
          this.resetForm()
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    async deleteUser() {
      this.isLoadingDelete = true
      try {
        await Api.delete(`admin/users/${this.user.id}`)
        this.showMainSnackbar('User deleted successfully!')
        this.$router.replace('/users')
      } catch (e) {
        this.showSnackbar(get(e, 'response.data.message'), 'error')
      } finally {
        this.deleteDialog = false
        this.isLoadingDelete = false
      }
    },
    blockAccount(value) {
      this.form.$busy = true
      const action = this.user.blocked_at ? 'unblocked' : 'blocked'
      this.blockUser({
        id: this.user.id,
        action,
      })
        .then(() => {
          this.form.$busy = false
          this.showModal = false
          this.showSnackbar(`User successfully ${action}!`, 'success')
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    setLocation(id) {
      this.home_address_place_id = id
    },
  },

  created() {
    this.getUser()
  },

  destroyed() {
    this.clearUserDetails()
  },

  watch: {
    $route() {
      this.getUser()
    },
  },
}
</script>

<style lang="scss" scoped>
.user-form {
  max-width: 400px;

  @media (max-width: 420px) {
    width: 100%;
  }
}
</style>
