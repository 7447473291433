<template>
  <div class="py-8">
    <div class="ma-4" v-if="form">
      <div class="display-2 font-weight-bold my-6 text-capitalize">
        {{ displayName }}
      </div>

      <div>
        <CategoryImage
          @change="onChangeImage"
          :src="imageSrc"
          @remove="onRemoveImage"
        />
      </div>

      <div class="d-flex my-6">
        <v-form ref="form" v-if="form" class="category-form">
          <label class="text-field-label">Label</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            v-model="form.label"
            :error-messages="form.$getError('label')"
            :loading="loading"
          ></v-text-field>

          <v-switch
            v-model="form.is_published"
            label="Published"
            class="mt-0"
          ></v-switch>
          <v-switch
            v-model="form.is_featured"
            label="Featured"
            class="mt-0"
          ></v-switch>

          <h3 class="py-5 primary--text">Sub-Categories</h3>
          <SubCategoriesTable :form="form" />

          <div class="d-md-flex d-sm-block mt-15">
            <v-btn
              color="error"
              class="mr-auto px-6"
              height="40px"
              outlined
              @click="deleteDialog = true"
              v-if="!isCreating"
            >
              <v-icon>{{ icons.delete }}</v-icon>
              Delete Category
            </v-btn>
            <v-btn
              color="primary"
              class="ml-auto px-6"
              height="40px"
              @click="updateCategoryDetails"
              :loading="form.$busy"
            >
              <v-icon>{{ icons.save }}</v-icon>
              Save <span v-if="!isCreating">Changes</span>
            </v-btn>
          </div>
        </v-form>
      </div>
    </div>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>

    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Delete category?</v-card-title>

        <v-card-text
          >Are you sure you want to delete this category?</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="deleteDialog = false">Cancel</v-btn>

          <v-btn
            color="error"
            @click="deleteCategory"
            :loading="isLoadingDelete"
            >Yes, Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiCheck,
  mdiAlertCircleOutline,
  mdiContentSave,
  mdiDelete,
} from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import { get } from 'lodash'

import Form from '@/utils/form'
import SubCategoriesTable from '@/views/Home/Categories/components/SubCategoriesTable'
import CategoryImage from '@/views/Home/Categories/components/CategoryImage'

export default {
  name: 'CategoryForm',
  components: { CategoryImage, SubCategoriesTable },
  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
        save: mdiContentSave,
        delete: mdiDelete,
      },
      loading: false,
      avatarLoading: false,
      deleteDialog: false,
      form: new Form({
        label: null,
        image: null,
        is_published: false,
        is_featured: false,
        subcategories: [],
      }),
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
      isLoadingDelete: false,
      imageSrc: null,
    }
  },

  computed: {
    ...mapState({
      category: (state) => state.category.categoryDetails,
    }),
    displayName() {
      if (this.isCreating) {
        return 'Create category'
      }

      if (this.category) {
        return this.category.label
      }

      return null
    },
    isCreating() {
      return this.$route.params.id === 'create'
    },
  },

  methods: {
    ...mapActions({
      getCategoryDetails: 'category/getCategoryDetails',
      updateCategory: 'category/updateCategory',
      createCategory: 'category/createCategory',
    }),

    ...mapMutations({
      clearCategoryDetails: 'category/clearCategoryDetails',
    }),

    async getCategory() {
      this.loading = true
      await this.getCategoryDetails(this.$route.params.id)
      this.form = new Form(this.category)
      if (this.category.image) {
        this.imageSrc = this.category.image.url
      }
      this.loading = false
    },

    upsert() {
      const data = this.form.$data()
      if (this.isCreating) {
        return this.createCategory(data)
      } else {
        return this.updateCategory(data)
      }
    },

    async updateCategoryDetails() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.upsert()
        .then(() => {
          this.form.$busy = false
          this.form.image = null
          this.showSnackbar(
            this.isCreating
              ? 'New category has been created.'
              : 'Category details successfully updated!',
            'success'
          )

          if (this.isCreating) {
            this.$router.push({
              name: 'categories',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    deleteCategory() {
      this.isLoadingDelete = true
      this.category
        .delete()
        .then(() => {
          this.showSnackbar('Category deleted successfully!')
          this.$router.replace('/categories')
        })
        .catch((e) => {
          this.showSnackbar(get(e, 'response.data.message'), 'error')
        })
        .finally(() => {
          this.isLoadingDelete = false
          this.deleteDialog = false
        })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    onChangeImage({ id, url }) {
      this.imageSrc = url
      this.form.image = { id }
    },

    onRemoveImage() {
      this.imageSrc = null
      this.form.image = null

      if (this.category.image) {
        this.form.image = {
          id: this.category.image.id,
          delete: true,
        }
      }
    },
  },

  created() {
    if (this.$route.params.id !== 'create') {
      this.getCategory()
    }
  },

  destroyed() {
    this.clearCategoryDetails()
  },

  watch: {
    $route() {
      this.getCategory()
    },
  },
}
</script>

<style lang="scss" scoped>
.category-form {
  width: 700px;

  @media (max-width: 420px) {
    width: 100%;
  }
  .v-text-field {
    max-width: 25rem;
  }
}
</style>
