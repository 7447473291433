<template>
  <div>
    <v-form ref="form" class="password-form">
      <v-divider></v-divider>

      <div class="mt-10">
        <label class="text-field-label mt-10">New Password</label>
        <v-text-field
          flat
          solo
          required
          type="password"
          class="mt-2"
          v-model="form.new_password"
          :error-messages="form.$getError('new_password')"
          :loading="loading"
        ></v-text-field>

        <label class="text-field-label mt-10">Confirm New Password</label>
        <v-text-field
          flat
          solo
          required
          type="password"
          class="mt-2"
          v-model="form.new_password_confirmation"
          :error-messages="form.$getError('new_password_confirmation')"
          :loading="loading"
        ></v-text-field>
      </div>

      <div class="mt-4">
        <v-btn
          color="primary"
          class="mr-4 px-6"
          height="40px"
          width="100%"
          @click="submit"
          :loading="form.$busy"
          :disabled="!form.new_password"
          >Update Password</v-btn
        >
      </div>
    </v-form>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import Form from '@/utils/form'
import Api from '@/services/api'

export default {
  data() {
    return {
      form: new Form({
        new_password: null,
        new_password_confirmation: null,
      }),
      loading: false,
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },
  methods: {
    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
    submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      Api.put(`admin/users/${this.$route.params.id}`, this.form.$data())
        .then(() => {
          this.showSnackbar('Password has been updated.', 'success')
          this.form.$reset()
        })
        .catch((e) => {
          if (e.response.status === 422) {
            this.form.$setErrors(e.response.data.errors)
          }
        })
        .finally(() => {
          this.form.$busy = false
        })
    },
  },
}
</script>

<style scoped></style>
